<template>
  <v-row no-gutters>
    <v-col>
      <v-app-bar class="upper-bar-container" fixed>
        <v-row align="center" no-gutters>
          <v-col class="pl-10" cols="6">
            <v-img
              :src="require('../../../assets/logo.png')"
              width="50"
            ></v-img>
          </v-col>
          <v-col class="pr-10" align="end" cols="6">
            <v-btn
              @click="changeStatusNavigationMenu"
              height="50"
              width="50"
              icon
            >
              <simple-svg
                :src="require('../../../assets/svg/burger_menu.svg')"
              />
            </v-btn>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-navigation-drawer
        v-model="isOpened"
        height="100vh"
        fixed
        right
        temporary
      >
        <v-row class="py-5" no-gutters>
          <v-col>
            <v-row align="center" no-gutters>
              <v-col align="center">
                <v-img
                  class="mb-5"
                  :src="require('../../../assets/logo.png')"
                  width="50"
                ></v-img>
              </v-col>
            </v-row>
            <v-list dense>
              <v-list-item
                v-for="item in navMenuItems"
                :key="item.title"
                @click="navigate(item.namePath)"
                link
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-navigation-drawer>
    </v-col>
  </v-row>
</template>

<script>
import { RemoveAllCookies } from "../../../utils/cookiesUtility";

export default {
  name: "UpperBar",
  data: () => ({
    isOpened: false,
    navMenuItems: [
      {
        title: "Logout",
        namePath: "AuthPage",
        icon: "mdi-exit-run",
      },
    ],
  }),
  methods: {
    changeStatusNavigationMenu() {
      this.isOpened = !this.isOpened;
    },
    navigate(namePath) {
      if (this.$route.name === namePath) {
        this.changeStatusNavigationMenu();
        return;
      }
      if (namePath === "AuthPage") {
        this.$store.dispatch("logout");
        RemoveAllCookies();
      }
      this.$router.push({
        name: namePath,
      });
    },
  },
};
</script>

<style scoped>
.upper-bar-container {
  max-height: 64px !important;
}
</style>
