<template>
  <v-container fill-height fluid>
    <v-card
      class="mx-auto pa-10"
      min-height="400"
      min-width="600"
      elevation="5"
    >
      <v-form ref="form" v-model="isValid" @submit.prevent="signIn">
        <v-card-title>
          <span class="mx-auto">Email</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field v-model="email" label="Email" :rules="rules.required" />
          <v-text-field
            v-model="password"
            label="Password"
            type="password"
            :rules="rules.required"
          />
        </v-card-text>
        <v-card-actions>
          <v-row align="center" no-gutters>
            <v-col class="pl-5" cols="6">
              <v-checkbox
                v-model="isRememberMe"
                label="Remember me"
              ></v-checkbox>
            </v-col>
            <v-col class="pr-5" align="end" cols="6">
              <v-btn
                type="submit"
                width="150"
                color="primary"
                :loading="isRequestWaiting"
                >Sign In</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
    <SingleActionPopUp
      :isOpened="isErrorDialog"
      :title="'Login or password not valid'"
      :buttonText="'Ok'"
      @clickAction="closeErrorDialog"
    ></SingleActionPopUp>
  </v-container>
</template>

<script>
import {
  emailKey,
  passwordKey,
  tokenKey,
} from "../utils/cookiesUtility.js";
import Rules from "../config/rules.js";
import {AUTHORIZATION_URL} from "../config/http";
import SingleActionPopUp from "../components/shared/SingleActionPopUp.vue";

export default {
  name: "AuthPage",
  data: () => ({
    email: "",
    password: "",
    isValid: false,
    isRememberMe: true,
    isRequestWaiting: false,
    rules: Rules,
    isErrorDialog: false,
  }),
  created() {
    this.email = this.$cookies.get(emailKey);
    this.password = this.$cookies.get(passwordKey);
  },
  methods: {
    signIn() {
      this.isRequestWaiting = true;
      this.$refs.form.validate();
      if (!this.isValid) {
        this.isRequestWaiting = false;
        return;
      }
      if (this.isRememberMe) {
        this.$cookies.set(emailKey, this.email);
        // this.$cookies.set(passwordKey, this.password);
      }

      this.$http
        .post(AUTHORIZATION_URL, { email: this.email, password: this.password })
        .then((response) => {
          response.json().then((data) => {
            if (this.isRememberMe) {
              this.$cookies.set(tokenKey, data.token);
            }

            this.$store.dispatch("trySetToken", {
              token: data.token,
            });
            this.$router.push({
              name: "AppPage",
            });
            this.isRequestWaiting = false;
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isErrorDialog = true;
          this.isRequestWaiting = false;
        });
    },

    closeErrorDialog() {
      this.isErrorDialog = false;
    },
  },
  components: {
    SingleActionPopUp,
  },
};
</script>

<style></style>
