const getDefaultState = () => {
  return {
    email: null,
    token: null,
    refreshToken: null,
  };
};

export default {
  state: getDefaultState(),
  getters: {
    token: (state) => state.token,
    currencies: (state) => state.currencies
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setCurrencies(state, currencies) {
      state.currencies = currencies;
    },
    logout(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    trySetToken({ commit }, { token }) {
      commit("setToken", token);
    },
    trySetCurrencies({ commit }, { currencies }) {
      commit("setCurrencies", currencies);
    },
    logout({ commit }) {
      commit("logout");
    },
  },
};
