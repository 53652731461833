<template>
  <v-row align="center" no-gutters>
    <v-col align="center" cols="12">
      <v-card class="pa-5" elevation="4">
        <FiltersContainer
          :isRequestWaiting="isRequestWaiting"
          @updateParams="updateParamsHandler($event)"
        ></FiltersContainer>
        <v-data-table
          :headers="headers"
          :items="items"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :loading="isRequestWaiting"
          :options.sync="options"
          :custom-sort="customSort"
          :server-items-length="totalItems"
          :footer-props="{
            'items-per-page-options': rowsPerPageItems,
          }"
        >
          <template v-slot:item.lastUpdated="{ item }">
            <span>
              {{ getDateTimeFormat(item.lastUpdated) }}
            </span>
          </template>
          <template v-for="column in specialHeaders" v-slot:[`item.${column}`]="{ item }">
            {{ getValueFormat(item[column], column) }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import {GET_ALL_USERS_URL, GET_CURRENCIES_URL} from "../../config/http";
import FiltersContainer from "./FiltersContainer.vue";
import BigNumber from "bignumber.js";
import Vue from "vue";
import store from "../../plugins/vuex";

export default {
  name: "SessionsTable",
  data: () => ({
    headers: [
      { text: "Username", value: "username", sortable: false},
      { text: "Last Activity", align: "start", value: "lastUpdated", sortable: true },
    ],
    isRequestWaiting: true,
    items: [],
    totalItems: 0,
    options: {},
    rowsPerPageItems: [5, 10, 20, 50, 100],
    params: {},
    sortBy: 'lastUpdated',
    sortDesc: false,
    specialHeaders: [
        '1_value',
        '2_value',
        '3_value',
        '4_value',
        '5_value',
        '6_value',
        '7_value',
        '8_value',
        '9_value',
        '10_value',
    ]
  }),
  created() {
    Vue.http
        .get(GET_CURRENCIES_URL)
        .then((response) => {
          response.json().then((data) => {
            store.dispatch("trySetCurrencies", { currencies: data.items });
            this.$store.getters.currencies.forEach(currency => {
              const text = currency.symbol;
              const value = `${currency.id}_value`;
              this.headers.push({ text: text, value: value, sortable: true, align: "end" });
            });
          });
        })
        .catch((e) => {
          console.error(e.message);
        });
  },
  methods: {
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (isDesc != "false") {
          return a[index] < b[index] ? -1 : 1
        } else {
          return b[index] < a[index] ? -1 : 1
        }
      })
      return items
    },
    getItemsFromApi() {
      this.isRequestWaiting = true;
      const { page, itemsPerPage } = this.options;

      const sort = this.options.sortBy[0];
      const sortField = sort === 'lastUpdated' ? 'updated' : (sort ? sort.split('_')[0] : 'updated');
      const sortType = this.options.sortDesc[0] ? 'asc' : 'desc';
      const skipCount = (page - 1) * itemsPerPage;

      const params = {
        ...this.params,
        skip: skipCount > 0 ? skipCount : 0,
        limit: itemsPerPage,
        sortField,
        sortType
      };

      this.$http
        .get(GET_ALL_USERS_URL, {
          params: params,
        })
        .then((response) => {
          response.json().then((data) => {
            console.log(data);
            this.totalItems = data.count;
            this.items = data.items;
            this.isRequestWaiting = false;
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isRequestWaiting = false;
        });
    },
    getDateTimeFormat(timestamp) {
      return moment(timestamp).format("MMMM DD, YYYY - LT");
    },
    getValueFormat(value, name) {
      const currencyId = Number.parseInt(name.split('_')[0]);
      const currency = this.$store.getters.currencies.find(item => item.id === currencyId);

      const powedDecimals = new BigNumber(10).pow(currency.decimals);
      const bnValue = new BigNumber(value);

      return bnValue.dividedBy(powedDecimals).toFixed(8);
    },
    updateParamsHandler(params) {
      this.params = params;
      this.options.page = 1;
      this.getItemsFromApi();
    },
  },
  watch: {
    options: {
      handler() {
        this.getItemsFromApi();
      },
      deep: true,
    },
  },
  components: {
    FiltersContainer,
  },
};
</script>

<style scoped>
@import "../../styles/styles.css";
</style>
