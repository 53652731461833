import Vue from "vue";
import store from "../plugins/vuex";
import VueRouter from "vue-router";

import { tokenKey } from "../utils/cookiesUtility";

import AppPage from "../views/AppPage.vue";
import AuthPage from "../views/AuthPage.vue";

import Users from "../components/appPage/Users.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/app",
  },
  {
    path: "/auth",
    name: "AuthPage",
    component: AuthPage,
    meta: { notRequiresAuth: true },
  },
  {
    path: "/app",
    name: "AppPage",
    redirect: "app/users",
    component: AppPage,
    meta: { requiresAuth: true },
    children: [
      {
        path: "users",
        name: "UsersPage",
        component: Users,
      }
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const saveTokenToStore = () => {
  const token = Vue.$cookies.get(tokenKey);
  store.dispatch("trySetToken", { token });
};


router.beforeEach((to, from, next) => {
  const isAuth = store.getters.token !== null;

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const NotRequiresAuth = to.matched.some(
    (record) => record.meta.notRequiresAuth
  );

  if (isAuth && requiresAuth && !NotRequiresAuth) {
    next();
    return;
  } else if (NotRequiresAuth && !isAuth) {
    next();
    return;
  }

  let isToken = Vue.$cookies.get(tokenKey) !== null;

  if (isToken && requiresAuth) {
    saveTokenToStore();
    next();
  } else {
    if (!isToken && !isAuth) {
      next("/auth");
    }
  }
});

export default router;
