<template>
  <v-row class="filter-container pa-4 mb-5" align="center" no-gutters>
    <v-col align="center" cols="12">
      <v-row align="center" no-gutters>
        <v-col class="px-2">
          <v-text-field
            v-model="customer"
            label="Username"
            color="primary"
            v-on:keyup.enter="filter"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" no-gutters>
        <v-col class="px-2" cols="3">
          <v-btn
            color="primary"
            block
            :loading="isRequestWaiting"
            @click="resetState"
            >Reset</v-btn
          >
        </v-col>
        <v-col class="px-2" cols="9">
          <v-btn
            color="primary"
            block
            :loading="isRequestWaiting"
            @click="filter"
            >Filter</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "FiltersContainer",
  data: () => ({
    customer: null,
  }),
  methods: {
    resetState() {
      this.customer = null;
      this.$emit("updateParams", {});
    },
    filter() {
      let params = {};
      if (this.isValidParam(this.customer)) {
        params["username"] = this.customer;
      }
      this.$emit("updateParams", params);
    },
    isValidParam(param) {
      return param !== null && param !== undefined && param !== "";
    },
  },
  computed: {
  },
  props: {
    isRequestWaiting: Boolean,
  },
};
</script>

<style scoped>
.filter-container {
  border: 2px solid lightgray;
  border-radius: 12px !important;
}
</style>
