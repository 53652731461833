const HOST = "https://cryptowallet.fgp-dev.me/api/v1/";

const PUBLIC_URL = "public/";
const ADMIN_URL = "admin/";

const AUTHORIZATION_URL = HOST + PUBLIC_URL + "admin/login";
const GET_ALL_USERS_URL = HOST + ADMIN_URL + "users";
const GET_CURRENCIES_URL = HOST + ADMIN_URL + "currencies";
// const getAllScansBySessionIdUrl = (sessionId) =>
//   HOST + ADMIN_URL + "sessions/" + sessionId;
// const getImageDataByScanIdUrl = (scanId) =>
//   HOST + ADMIN_URL + "scans/" + scanId + "/image-data";
// const getUpdateScanByIdUrl = (scanId) => HOST + ADMIN_URL + "scans/" + scanId;

export {
  AUTHORIZATION_URL,
  GET_ALL_USERS_URL,
  GET_CURRENCIES_URL,
  // getAllScansBySessionIdUrl,
  // getImageDataByScanIdUrl,
  // getUpdateScanByIdUrl,
};
