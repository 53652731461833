<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-row class="mb-16" no-gutters>
        <v-col>
          <UpperBar></UpperBar>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import UpperBar from "../components/core/upperBar/UpperBar.vue";

export default {
  name: "AppPage",
  data: () => ({
    isOpenedNavigation: false,
  }),
  methods:{
    openNavigation(){
      this.isOpenedNavigation = !this.isOpenedNavigation;
    }
  },
  components: {
    UpperBar,
  },
};
</script>

<style></style>
