import Vue from "vue";

export const emailKey = "email";
export const passwordKey = "password";
export const tokenKey = "token"

export function RemoveAllCookies() {
  Vue.$cookies.remove(emailKey);
  Vue.$cookies.remove(passwordKey);
  Vue.$cookies.remove(tokenKey);
}
