<template>
  <v-row no-gutters>
    <v-col>
      <v-row class="my-5 px-5" no-gutters>
        <v-col>
          <SessionsTable @selectCar="onSelectCarHandler($event)"></SessionsTable>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import SessionsTable from "../usersPage/UsersTable.vue";

export default {
  name: "Sessions",
  data: () => ({}),
  methods: {
    onSelectCarHandler(car) {
      console.log(car);
      this.$router.push({
        name: "DetailScanPage",
        params: {
          sessionId: car.sessionId,
        },
      });
    },
  },
  components: {
    SessionsTable,
  },
};
</script>

<style></style>
